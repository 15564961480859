import React from 'react';
import {
  Box,
  VStack,
  ScrollView,
  HStack,
  Icon,
  Text,
  Divider,
  Avatar,
  Button,
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

const featureList = [
  {
    iconName: 'home-work',
    iconText: 'My Clinic Profiles',
    page: 'ClinicProfiles',
  },
  {
    iconName: 'campaign',
    iconText: 'My Job Postings',
    page: 'JobPostings',
  },
  {
    iconName: 'move-to-inbox',
    iconText: 'Job Applicants',
    page: 'JobApplications',
  },
  // {
  //   iconName: 'person-search',
  //   iconText: 'Job Postings',
  //   link: '',
  // },
  // {
  //   iconName: 'assignment-ind',
  //   iconText: 'Job Postings',
  //   link: '',
  // },
  // {
  //   iconName: 'campaign',
  //   iconText: 'Job Postings',
  // },
  // {
  //   iconName: 'work',
  //   iconText: 'Job Postings',
  // },
  // {
  //   iconName: 'groups',
  //   iconText: 'Candidate Profiles',
  // },
  // {
  //   iconName: 'person-search',
  //   iconText: 'Candidate Profiles',
  // },
  // {
  //   iconName: 'contacts',
  //   iconText: 'Candidate Profiles',
  // },
  // {
  //   iconName: 'inbox',
  //   iconText: 'Candidate Profiles',
  // },
  // {
  //   iconName: 'move-to-inbox',
  //   iconText: 'Job Applicants',
  // },
  // {
  //   iconName: 'people',
  //   iconText: 'Candidate Profiles',
  // },
  // {
  //   iconName: 'recent-actors',
  //   iconText: 'Job Applicants',
  // },
  // {
  //   iconName: 'contact-page',
  //   iconText: 'Job Applicants',
  // },
];

const adminList = [
  {
    iconName: 'support-agent',
    iconText: 'Help & Support',
    link: '',
  }
];

export default function Sidebar(props: any) {
  return (
    <Box
      w={props.floating ? 320 : 80}
      h={props.floating ? '100%' : 'auto'}
      borderRightWidth="1"
      position={props.floating ? 'absolute' : 'relative'}
      zIndex={props.floating ? 1000 : 'auto'}
      display="flex"
      _light={{ bg: 'white', borderRightColor: 'coolGray.200' }}
      _dark={{ bg: 'coolGray.900', borderRightColor: 'coolGray.800' }}
    >
      <ScrollView>
        <VStack
          pb="4"
          mt="10"
          space="3"
          alignItems="center"
          borderBottomWidth="1"
          _light={{
            borderBottomColor: 'coolGray.200',
          }}
          _dark={{
            borderBottomColor: 'coolGray.800',
          }}
        >
          <Avatar
            source={require('../assets/avatar.png')}
            width={{ base: 20, md: 40 }}
            height={{ base: 20, md: 40 }}
          />
          <HStack alignItems="center" justifyContent="center" space="2">
            <Text
              fontSize="xl"
              fontWeight="bold"
              _light={{ color: 'coolGray.800' }}
            >
              User Name
            </Text>
          </HStack>
          <Text
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
            _light={{ color: 'coolGray.500' }}
          >
            user@mydomain.com
          </Text>
        </VStack>
        <VStack px="4" py="4">
          {featureList.map((item, idx) => {
            return (
              <Button
                key={idx}
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
                onPress={() => {
                  props.navigation.navigate(item.page);
                }}
              >
                <HStack space="4" alignItems="center">
                  <Icon
                    size="6"
                    as={MaterialIcons}
                    name={item.iconName}
                    _dark={{ color: 'coolGray.50' }}
                    _light={{ color: 'coolGray.500' }}
                  />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: 'coolGray.50' }}
                    _light={{ color: 'coolGray.800' }}
                  >
                    {item.iconText}
                  </Text>
                </HStack>
              </Button>
            );
          })}
        </VStack>
        <Divider _dark={{ bgColor: 'coolGray.800' }} />
        <VStack px="4" py="4">
          {adminList.map((item, idx) => {
            return (
              <Button
                key={idx}
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
              >
                <HStack space="4" alignItems="center">
                  <Icon
                    size="6"
                    as={MaterialIcons}
                    name={item.iconName}
                    _dark={{ color: 'coolGray.50' }}
                    _light={{ color: 'coolGray.500' }}
                  />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: 'coolGray.50' }}
                    _light={{ color: 'coolGray.800' }}
                  >
                    {item.iconText}
                  </Text>
                </HStack>
              </Button>
            );
          })}
        </VStack>
      </ScrollView>
    </Box>
  );
}
