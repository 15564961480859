import React from 'react';
import {
  VStack,
  Box,
  HStack,
  Icon,
  Text,
  Link,
  Image,
  IconButton,
  Center,
  Hidden,
} from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import GuestLayout from '../../layouts/GuestLayout';

export default function CheckEmailForMagicLink(props: any) {
  return (
    <GuestLayout>
      <Hidden from="md">
        <HStack space="2" px="1" mt="4" pb="4" alignItems="center">
          <IconButton
            variant="unstyled"
            onPress={() => props.navigation.navigate("SignIn")}
            icon={
              <Icon
                alignItems="center"
                justifyContent="center"
                size="6"
                as={AntDesign}
                name="arrowleft"
                color="coolGray.50"
              />
            }
          />
          <Text color="coolGray.50" fontSize="lg">
            Forgot Password
          </Text>
        </HStack>
      </Hidden>
      <Center
        flex={{ md: 1 }}
        pt={{ base: 9, md: '190px' }}
        pb={{ base: '52px', md: '190px' }}
        px={{ base: 4, md: '50px' }}
        _light={{ bg: { base: 'white', md: 'white' } }}
        _dark={{ bg: { base: 'coolGray.800', md: 'primary.900' } }}
        borderTopLeftRadius={{ md: 'xl' }}
        borderBottomLeftRadius={{ md: 'xl' }}
      >
        <Hidden from="base" till="md">
          <Image
            w={{ base: '205px' }}
            h={{ base: '160px' }}
            alt="Pontik X-Ray Tooth Logo "
            resizeMode={'contain'}
            source={require('../../assets/logo1.png')}
          />
        </Hidden>
        <Hidden from="md">
          <Image
            w={{ base: '205px', md: '501px' }}
            h={{ base: '160px', md: '544px' }}
            alt="Pontik X-Ray Tooth Logo "
            resizeMode={'contain'}
            source={require('../../assets/logo1.png')}
          // source={require('./components/forgot_password_mobile.png')}
          />
        </Hidden>
      </Center>
      <Box
        pt={{ base: '0', md: '12' }}
        pb={{ base: '6', md: '12' }}
        px={{ base: '4', md: '10' }}
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        flex="1"
        borderTopRightRadius={{ md: 'xl' }}
        borderBottomRightRadius={{ md: 'xl' }}
      >
        <VStack justifyContent="space-between" flex="1" space="0">
          <Box>
            <VStack space={{ base: '2', md: '5' }}>
              <Text
                fontSize="xl"
                fontWeight="bold"
                _dark={{ color: 'coolGray.50' }}
                _light={{ color: 'coolGray.800' }}
                textAlign={{ base: 'center', sm: 'left' }}
              >
                Check your inbox!
              </Text>
              <Text
                _light={{ color: 'coolGray.800' }}
                _dark={{ color: 'coolGray.400' }}
                textAlign={{ base: 'center', sm: 'left' }}
              >
                If the address you entered is registered in our system, you&apos;ll receive an email to help you log in!
              </Text>
            </VStack>
          </Box>
        </VStack>
        <Hidden till="md">

          <VStack alignItems="center">
            <HStack space="1" position="absolute" bottom="0">
              <Link
                onPress={() => props.navigation.navigate("SignIn")}
                _text={{
                  fontWeight: 'bold',
                  textDecorationLine: 'none',
                }}
                _hover={{
                  _text: {
                    color: 'primary.500',
                  },
                }}
                _light={{
                  _text: {
                    color: 'primary.900',
                  },
                }}
                _dark={{
                  _text: {
                    color: 'primary.700',
                  },
                }}
              >
                Back
              </Link>
            </HStack>
          </VStack>
        </Hidden>
      </Box>
    </GuestLayout>
  );
}
