import React, { useState } from 'react';
import {
  Icon,
  VStack,
  useColorModeValue,
  Button,
  useToast,
  IconButton,
} from 'native-base';
import { Entypo } from "@expo/vector-icons";
import FloatingLabelInput from '../../components/FloatingLabelInput';
import DashboardLayout from '../../layouts/DashboardLayout';
import { API_HOST } from '@env';

export default function ChangePassword(props: any) {
  const toast = useToast();

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const submitForm = () => {
    const xsrfPreflight = () => {
      return fetch(API_HOST + '/auth/csrf-cookie', {
        method: 'GET',
        credentials: 'include',
      })
    }

    const readXsrfCookie = () => {
      const cookieName = 'XSRF-TOKEN'
      const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
      if (!match || !match[2]) {
        console.log('Error fetching XSRF cookie.');

        return '';
      }

      // Cookie is url encoded - if we don't decode it, the server will throw an error when trying to read it.
      const xsrfCookieValue = decodeURIComponent(match[2]);
      return xsrfCookieValue;
    }

    const updatePassword = () => {
      const xsrfCookie = readXsrfCookie()

      const data: RequestInit = {
        method: 'PATCH',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          password: password,
          password_confirmation: confirmPassword,
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': xsrfCookie
        }
      }

      return fetch(API_HOST + '/clinic/users/me', data)
        .then(response => response.json())
        .then(json => {
          if (json['status'] === 'fail') {
            alert('fail')
          } else if (json['status'] === 'success') {
            toast.show({
              title: 'Password Updated!',
              placement: 'bottom-right',
              // variant: 'solid',
              // status: 'success',
              isClosable: false,
              // color: 'blue.400',
              backgroundColor: 'green.600',
              marginRight: 20,
              paddingX: 8,
              paddingY: 5
            })

            props.navigation.navigate("EditAccount")
          } else {
            alert('error')
          }
        })
    }

    if (readXsrfCookie()) {
      return updatePassword();
    } else {
      return xsrfPreflight().then(() => updatePassword())
    }
  }

  return (
    <DashboardLayout title="Change My Password" navigation={props.navigation}>
      <VStack
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 16, md: 8 }}
        borderRadius={{ md: '8' }}
        _light={{
          borderColor: 'coolGray.200',
          bg: { base: 'white' },
        }}
        _dark={{
          borderColor: 'coolGray.800',
          bg: { md: 'coolGray.900', base: 'coolGray.800' },
        }}
        borderWidth={{ md: '1' }}
        borderBottomWidth="1"
        space="4"
      >
        <FloatingLabelInput
          isRequired
          type={showPass ? "" : "password"}
          label="Password"
          borderRadius="4"
          labelColor="#9ca3af"
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          onChangeText={(txt: string) => setPassword(txt)}
          InputRightElement={
            <IconButton
              variant="unstyled"
              icon={
                <Icon
                  size="4"
                  color="coolGray.400"
                  as={Entypo}
                  name={showPass ? "eye-with-line" : "eye"}
                />
              }
              onPress={() => {
                setShowPass(!showPass);
              }}
            />
          }
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />

        <FloatingLabelInput
          isRequired
          type={showConfirmPass ? "" : "password"}
          label="Confirm Password"
          borderRadius="4"
          labelColor="#9ca3af"
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          onChangeText={(txt: string) => setConfirmPassword(txt)}
          InputRightElement={
            <IconButton
              variant="unstyled"
              icon={
                <Icon
                  size="4"
                  color="coolGray.400"
                  as={Entypo}
                  name={showConfirmPass ? "eye-with-line" : "eye"}
                />
              }
              onPress={() => {
                setShowConfirmPass(!showConfirmPass);
              }}
            />
          }
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />
        <Button
          mt={10}
          onPress={submitForm}
          size="md"
          borderRadius="4"
          _text={{
            fontSize: 'sm',
            fontWeight: 'medium',
          }}
          _light={{
            bg: 'primary.900',
          }}
          _dark={{
            bg: 'primary.700',
            _pressed: { bg: 'primary.500' },
          }}
        >
          Change Password
        </Button>
      </VStack>
    </DashboardLayout>
  );
}
