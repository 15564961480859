import React from "react";
import {
  Box,
  StatusBar,
  Center,
} from "native-base";

export default function Splash(props: any) {
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{ bg: "primary.900" }}
        _dark={{ bg: "coolGray.900" }}
      />
      <Center
        my="auto"
        _dark={{ bg: "coolGray.900" }}
        _light={{ bg: "primary.900" }}
        flex="1"
        p={{ md: 8 }}
      >
      </Center>
    </>
  );
}
