import React, { useEffect, useState } from 'react';
import {
  HStack,
  Icon,
  Text,
  VStack,
  Avatar,
  Pressable,
  useColorModeValue,
  Button,
  Modal,
  useDisclose,
  Center,
  useBreakpointValue,
} from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import DashboardLayout from '../../layouts/DashboardLayout';
import IconPhoto from './components/IconPhoto';
import IconRemovePhoto from './components/IconRemovePhoto';
import IconCamera from './components/IconCamera';
import { Platform } from 'react-native';
import { API_HOST } from '@env';

export default function EditAccount(props: any) {
  const { onOpen } = useDisclose();

  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')

  const bgColor = useBreakpointValue({
    base: '#1f2937',
    lg: '#111827',
    md: '#111827',
    xl: '#111827',
  });

  useEffect(() => {
    (() => {
      return fetch(API_HOST + '/clinic/users/me', { credentials: 'include' })
    })().then((res) => res.json()).then((json) => {
      if (json['status'] == 'success') {
        const data = json['data'][0]
        setEmail(data['email'])
        setFirstName(data['first_name'])
        setLastName(data['last_name'])
        setPhone(data['phone'])
      } else {
        alert(json['message']);
      }
    })
  }, [])

  return (
    <DashboardLayout title="My Account" navigation={props.navigation}>
      <VStack
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 16, md: 8 }}
        borderRadius={{ md: '8' }}
        _light={{
          borderColor: 'coolGray.200',
          bg: { base: 'white' },
        }}
        _dark={{
          borderColor: 'coolGray.800',
          bg: { md: 'coolGray.900', base: 'coolGray.800' },
        }}
        borderWidth={{ md: '1' }}
        borderBottomWidth="1"
        space="4"
      >
        <HStack
          mb="3"
          alignItems="center"
          justifyContent={{ md: 'space-between', base: 'space-around' }}
        >
          <Avatar source={require('../../assets/avatar.png')} w="24" h="24">
            <Avatar.Badge
              _light={{ bg: 'coolGray.50' }}
              _dark={{ bg: 'coolGray.50', borderColor: 'coolGray.50' }}
              p={3}
              alignItems="center"
              justifyContent="center"
            >
              <Pressable
                onPress={
                  Platform.OS === 'ios'
                    ? onOpen
                    : () => {
                      setModalVisible(!modalVisible);
                    }
                }
              >
                <Center>
                  <Icon
                    size="5"
                    as={Ionicons}
                    name={'camera-outline'}
                    _light={{ color: 'coolGray.900' }}
                    _dark={{ color: 'coolGray.500', bg: 'coolGray.50' }}
                  />
                </Center>
              </Pressable>
            </Avatar.Badge>
          </Avatar>
        </HStack>

        <FloatingLabelInput
          isReadOnly={true}
          p="3"
          isRequired
          borderRadius="4"
          label="Email"
          labelColor={useColorModeValue('#6b7280', '#d1d5db')}
          defaultValue={email}
          labelBGColor={useColorModeValue('#fff', bgColor)}
          _text={{
            fontSize: 'sm',
            fontWeight: 'medium',
          }}
          _dark={{
            borderColor: 'coolGray.700',
          }}
          _light={{
            borderColor: 'coolGray.200',
          }}
        />

        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            isReadOnly={true}
            p="3"
            w="100%"
            containerWidth="48%"
            isRequired
            borderRadius="4"
            label="First Name"
            labelColor={useColorModeValue('#6b7280', '#d1d5db')}
            defaultValue={firstName}
            labelBGColor={useColorModeValue('#fff', bgColor)}
            _text={{
              fontSize: 'sm',
              fontWeight: 'medium',
            }}
            _dark={{
              borderColor: 'coolGray.700',
            }}
            _light={{
              borderColor: 'coolGray.200',
            }}
          />
          <FloatingLabelInput
            isReadOnly={true}
            p="3"
            w="100%"
            containerWidth="48%"
            isRequired
            borderRadius="4"
            label="Last Name"
            labelColor={useColorModeValue('#6b7280', '#d1d5db')}
            defaultValue={lastName}
            labelBGColor={useColorModeValue('#fff', bgColor)}
            _text={{
              fontSize: 'sm',
              fontWeight: 'medium',
            }}
            _dark={{
              borderColor: 'coolGray.700',
            }}
            _light={{
              borderColor: 'coolGray.200',
            }}
          />
        </HStack>

        <FloatingLabelInput
          isReadOnly={true}
          p="3"
          borderRadius="4"
          label="Phone Number"
          labelColor={useColorModeValue('#6b7280', '#d1d5db')}
          defaultValue={phone}
          labelBGColor={useColorModeValue('#fff', bgColor)}
          _text={{
            fontSize: 'sm',
            fontWeight: 'medium',
          }}
          _dark={{
            borderColor: 'coolGray.700',
          }}
          _light={{
            borderColor: 'coolGray.200',
          }}
        />
        <Button
          mt={10}
          onPress={() => {
            props.navigation.navigate("ChangePassword")
          }}
          size="md"
          borderRadius="4"
          _text={{
            fontSize: 'sm',
            fontWeight: 'medium',
          }}
          _light={{
            bg: 'primary.900',
          }}
          _dark={{
            bg: 'primary.700',
            _pressed: { bg: 'primary.500' },
          }}
        >
          Change Password
        </Button>
      </VStack>
      <Modal
        isOpen={modalVisible}
        onClose={setModalVisible}
        size="md"
        marginX="auto"
      >
        <Modal.Content
          _dark={{ bg: 'coolGray.800' }}
          _light={{ bg: 'coolGray.50' }}
          px="10"
          py="6"
        >
          <Text
            fontSize="xl"
            fontWeight="medium"
            _light={{ color: 'coolGray.800' }}
            _dark={{ color: 'coolGray.50' }}
          >
            Profile Picture
          </Text>
          <HStack space="6" justifyContent="space-between" mt="8">
            <Pressable
              onPress={() => {
                console.log('hello');
              }}
            >
              <VStack space="1" alignItems="center">
                <IconPhoto />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: 'coolGray.800' }}
                  _dark={{ color: 'coolGray.50' }}
                >
                  Photos
                </Text>
              </VStack>
            </Pressable>
            <Pressable
              onPress={() => {
                console.log('hello');
              }}
            >
              <VStack space="1" alignItems="center">
                <IconCamera />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: 'coolGray.800' }}
                  _dark={{ color: 'coolGray.50' }}
                >
                  Camera
                </Text>
              </VStack>
            </Pressable>
            <Pressable
              onPress={() => {
                console.log('hello');
              }}
            >
              <VStack space="1" alignItems="center">
                <IconRemovePhoto />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: 'coolGray.800' }}
                  _dark={{ color: 'coolGray.50' }}
                >
                  Remove photo
                </Text>
              </VStack>
            </Pressable>
          </HStack>
        </Modal.Content>
      </Modal>
    </DashboardLayout>
  );
}
