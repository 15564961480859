import React, { useCallback, useEffect, useState } from 'react';
import {
  HStack,
  Icon,
  Text,
  VStack,
  Avatar,
  Pressable,
  useColorModeValue,
  Button,
  Modal,
  useDisclose,
  Center,
  useBreakpointValue,
  Spinner,
  ScrollView,
  Hidden,
  Divider,
  Image,
} from 'native-base';

import DashboardLayout from '../../layouts/DashboardLayout';
import { API_HOST } from '@env';
import { useFocusEffect } from '@react-navigation/native';

export default function JobApplications(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState([])

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      (() => {
        return fetch(API_HOST + '/clinic/job-applications', { credentials: 'include' })
      })().then((res) => res.json()).then((json) => {
        if (json['status'] == 'success') {
          let data = json['data']
          setApplications(data);
          setIsLoading(false);
        } else {
          alert(json['message']);
        }
      })

      return () => {
        // Do something when the screen is unfocused
        setIsLoading(true);
      }
    }, [])
  )

  return (
    <DashboardLayout title="Job Applicants" navigation={props.navigation}>
      <ScrollView px={3}>
        <HStack py={3} alignItems="center" justifyContent="space-between">
          <Hidden till="md">
            <Text
              fontSize="2xl"
              fontWeight="bold"
              _light={{ color: 'coolGray.800' }}
            >
              Job Applicants
            </Text>
          </Hidden>
        </HStack>
        <VStack
          px={{ md: 8 }}
          // py={{ base: 4, md: 8 }}
          borderRadius={{ md: '8' }}
          _light={{
            borderColor: 'coolGray.200',
            bg: { base: 'white' },
          }}
          _dark={{
            borderColor: 'coolGray.800',
            bg: { md: 'coolGray.900', base: 'coolGray.800' },
          }}
          borderWidth={{ md: '1' }}
          my={{ base: 2 }}
        >
          {isLoading
            ?
            <HStack w="100%" h="200" space={10} justifyContent="center" alignItems="center">
              <Spinner size="lg" color='primary.900' />
            </HStack>
            :
            <>
              {applications.map((item: any, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <HStack
                      key={index}
                      p={8}
                      // w="90%"
                      // borderWidth="1"
                      space={8}
                      // p={3}
                      // rounded="md"
                      _dark={{
                        borderColor: "coolGray.500"
                      }}
                      _light={{
                        borderColor: "coolGray.200"
                      }}
                    >
                      <Image src={item.display_picture_url} size="xl" />
                      <VStack flex="3" space="4">
                        <HStack space="2" alignItems="center" justifyContent="space-between">
                          <Text fontSize="xl" fontWeight="bold">{item.name}</Text>
                          <Text _light={{ color: 'coolGray.600' }}>{item.address}, {item.city}</Text>
                        </HStack>
                        <Text>{item.bio}</Text>
                        <HStack space="2" alignItems="center" justifyContent="end">
                          {/* <Text>ID: {item.id}</Text> */}
                          <Button
                            onPress={() => {
                              props.navigation.navigate('EditClinicProfile', { id: item.id })
                            }}
                            // variant="outline"
                            _dark={{ bg: 'secondary.700', _pressed: { bg: 'secondary.500' } }}
                            _light={{ bg: 'secondary.900' }}>Edit</Button>
                        </HStack>
                      </VStack>
                    </HStack>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </>
          }
        </VStack >
      </ScrollView >
    </DashboardLayout >
  );
}
