import React, { createContext, useReducer, useMemo, useEffect } from "react";
import * as SecureStore from "expo-secure-store";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import { NativeBaseProvider, extendTheme, theme as nbTheme } from "native-base";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Splash from "./screens/Splash";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import ForgotPassword from "./screens/ForgotPassword";
import CheckEmailForMagicLink from "./screens/CheckEmailForMagicLink";
import EditAccount from "./screens/EditAccount";
import ClinicProfiles from "./screens/ClinicProfiles";
import AddClinicProfile from "./screens/ClinicProfiles/add";
import EditClinicProfile from "./screens/ClinicProfiles/edit";
import ChangePassword from "./screens/ChangePassword";
import JobPostings from "./screens/JobPostings";
import JobApplications from "./screens/JobApplications";

import { API_HOST } from '@env';
import './App.css';

export const AuthContext = createContext<any>(null);

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.cyan,
  },
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'light',
  },
});

const Drawer = createDrawerNavigator();

function reducer(prevState: any, action: any) {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false,
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      };
  }
}

// https://reactnavigation.org/docs/configuring-links/#handling-unmatched-routes-or-404
const linking = {
  prefixes: [],
  config: {
    screens: {
      SignUp: 'signup',
      SignIn: 'login',
      ForgotPassword: 'forgot-password',
      CheckEmailForMagicLink: 'check-your-inbox',
      ClinicProfiles: 'clinic-profiles',
      AddClinicProfile: 'add-clinic-profile',
      EditClinicProfile: 'clinic-profiles/:id',
      EditAccount: 'my-account',
      ChangePassword: 'update-password',
      JobPostings: 'job-postings',
      JobApplications: 'job-applications',
    }
  },
};

export default function App() {
  const [state, dispatch] = useReducer(reducer,
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        // userToken = await SecureStore.getItemAsync('userToken');
        // if (userToken) {
        //   console.log('TRY token: ' + userToken)
        // }

        (() => {
          return fetch(API_HOST + '/clinic/users/me', { credentials: 'include' })
        })
          ().then((res) => res.json())
          .then((json) => {
            console.log('BOOTSTRAP JSON')
            console.log(json)

            if (json['status'] === 'success') {
              dispatch({ type: 'RESTORE_TOKEN', token: 'authenticated' });
            } else {
              dispatch({ type: 'RESTORE_TOKEN', token: null });
            }
          })
      } catch (e) {
        console.log('CATCH token: ' + e)
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      }
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data: any) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        dispatch({ type: 'SIGN_IN', token: 'authenticated' });
      },
      signOut: () => {
        (() => {
          const data: RequestInit = {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'X-XSRF-TOKEN': xsrfCookie
            }
          }
          return fetch(API_HOST + '/clinic/logout', data);
        })().then((res) => res.json())
          .then((json) => {
            if (json['status'] === 'success') {
              dispatch({ type: 'SIGN_OUT' })
            } else {
              console.log("Logout failed.")
            }
          })

      },
      signUp: async (data: any) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        dispatch({ type: 'SIGN_IN', token: 'authenticated' });
      },
    }),
    []
  );

  if (state.isLoading) {
    // We haven't finished checking for the token yet
    return (
      <NativeBaseProvider theme={theme}>
        <Splash />
      </NativeBaseProvider>
    )
  }

  return (
    <NativeBaseProvider theme={theme}>
      <NavigationContainer linking={linking}>
        <AuthContext.Provider value={authContext}>
          <GoogleReCaptchaProvider reCaptchaKey="6LdBfMwhAAAAAHI2zS-GTbfQM_QD3sXI1nqVgIfb" >

            <Drawer.Navigator screenOptions={{ headerShown: false }}>
              {state.userToken == null ? (
                <>
                  <Drawer.Screen name={"SignIn"} component={SignIn} options={{ title: 'Sign In' }} />
                  <Drawer.Screen name={"SignUp"} component={SignUp} options={{ title: 'Sign Up' }} />
                  <Drawer.Screen name={"ForgotPassword"} component={ForgotPassword} options={{ title: 'Forgot Password' }} />
                  <Drawer.Screen name={"CheckEmailForMagicLink"} component={CheckEmailForMagicLink} options={{ title: 'Forgot Password' }} />
                </>
              ) : (
                <>
                  <Drawer.Screen name={"EditAccount"} component={EditAccount} options={{ title: 'My Account' }} />
                  <Drawer.Screen name={"ClinicProfiles"} component={ClinicProfiles} options={{ title: 'My Clinic Profile' }} />
                  <Drawer.Screen name={"AddClinicProfile"} component={AddClinicProfile} options={{ title: 'Add Clinic Profile' }} />
                  <Drawer.Screen name={"EditClinicProfile"} component={EditClinicProfile} options={{ title: 'Clinic Profile' }} />
                  <Drawer.Screen name={"ChangePassword"} component={ChangePassword} options={{ title: 'My Account' }} />
                  <Drawer.Screen name={"JobPostings"} component={JobPostings} options={{ title: 'Job Postings' }} />
                  <Drawer.Screen name={"JobApplications"} component={JobApplications} options={{ title: 'Job Applications' }} />
                </>
              )}

            </Drawer.Navigator>
          </GoogleReCaptchaProvider>
        </AuthContext.Provider>
      </NavigationContainer>
    </NativeBaseProvider >
  );
}
