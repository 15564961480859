import React, { useCallback, useState } from 'react';
import {
  HStack,
  Text,
  VStack,
  Button,
  Spinner,
  Divider,
  ScrollView,
  Hidden,
} from 'native-base';

import DashboardLayout from '../../layouts/DashboardLayout';
import { API_HOST } from '@env';
import { useFocusEffect } from '@react-navigation/native';

export default function JobApplications(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([])

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      (() => {
        return fetch(API_HOST + '/clinic/job-posts', { credentials: 'include' })
      })().then((res) => res.json()).then((json) => {
        if (json['status'] == 'success') {
          const data = json['data']
          setPosts(data);
          setIsLoading(false);
        } else {
          alert(json['message']);
        }
      })

      return () => {
        // Do something when the screen is unfocused
        setIsLoading(true);
      }
    }, [])
  )

  return (
    <DashboardLayout title="My Job Postings" navigation={props.navigation}>
      <ScrollView px={3}>
        <HStack py={3} alignItems="center" justifyContent="space-between">
          <Hidden till="md">
            <Text
              fontSize="2xl"
              fontWeight="bold"
              _light={{ color: 'coolGray.800' }}
            >
              My Job Postings
            </Text>
          </Hidden>
          <Button
            p={3}
            fontWeight="bold"
            onPress={() => { props.navigation.navigate('AddClinicProfile') }}
            _dark={{ bg: 'primary.700', _pressed: { bg: 'primary.500' } }}
            _light={{ bg: 'primary.900' }}
          >
            New Job Posting
          </Button>
        </HStack>
        <VStack
          px={{ md: 8 }}
          // py={{ base: 4, md: 8 }}
          borderRadius={{ md: '8' }}
          _light={{
            borderColor: 'coolGray.200',
            bg: { base: 'white' },
          }}
          _dark={{
            borderColor: 'coolGray.800',
            bg: { md: 'coolGray.900', base: 'coolGray.800' },
          }}
          borderWidth={{ md: '1' }}
          my={{ base: 2 }}
        >
          {isLoading
            ?
            <HStack w="100%" h="200" space={10} justifyContent="center" alignItems="center">
              <Spinner size="lg" color='primary.900' />
            </HStack>
            :
            <>
              {posts.map((item: any, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <HStack
                      key={index}
                      p={8}
                      // w="90%"
                      // borderWidth="1"
                      space={8}
                      // p={3}
                      // rounded="md"
                      _dark={{
                        borderColor: "coolGray.500"
                      }}
                      _light={{
                        borderColor: "coolGray.200"
                      }}
                    >
                      <VStack flex="3" space="4">
                        <HStack space="2" alignItems="center" justifyContent="space-between">
                          <Text fontSize="xl" fontWeight="bold">{item.job_type.charAt(0).toUpperCase() + item.job_type.slice(1)} {item.job_title} @ {item.profile.name}</Text>
                          <Text fontSize="2xl" fontWeight="bold" _light={{ color: 'primary.600' }}>{item.status.toUpperCase()}</Text>
                        </HStack>
                        <Text fontSize="xl" fontWeight="bold">{item.title}</Text>
                        <Text>{item.description}</Text>
                        <HStack space="2" alignItems="center" justifyContent="space-between">
                          <Text>{item.start_date} - {item.end_date}</Text>
                          <Text>{item.pay_unit.charAt(0).toUpperCase() + item.pay_unit.slice(1)}: ${item.pay_rate_lower} - ${item.pay_rate_upper}</Text>
                          <Button
                            onPress={() => {
                              props.navigation.navigate('EditClinicProfile', { id: item.id })
                            }}
                            // variant="outline"
                            _dark={{ bg: 'secondary.700', _pressed: { bg: 'secondary.500' } }}
                            _light={{ bg: 'secondary.900' }}>Edit</Button>
                        </HStack>
                      </VStack>
                    </HStack>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </>
          }
        </VStack >
      </ScrollView>
    </DashboardLayout >
  );
}
