import React, { useCallback, useState } from 'react';
import {
  VStack,
  Button,
  useToast,
  useColorModeValue,
  HStack,
  Hidden,
  Text,
  Spinner,
  TextArea,
  Image,
  Pressable,
  IconButton,
  Icon,
} from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import { API_HOST } from '@env';
import {
  Ionicons,
} from '@expo/vector-icons';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import { useFocusEffect } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';

export default function EditClinicProfile(props: any) {
  const toast = useToast();

  const [profileId, setProfileId] = useState(props.route.params.id);
  const [pageHeading, setPageHeading] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [displayPicture, setDisplayPicture] = useState(new Blob())

  // Clinic fields
  const [name, setName] = useState('Yonge St Dental')
  const [country, setCountry] = useState('Canada')
  const [region, setRegion] = useState('Ontario')
  const [city, setCity] = useState('Toronto')
  const [address, setAddress] = useState('123 Testing St, M1S 3K1')
  const [bio, setBio] = useState('')
  const [displayPictureUri, setDisplayPictureUri] = useState('')

  console.log("PROP:" + props.route.params.id)
  console.log("PROFILE:" + profileId)

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      (() => {
        const id = props.route.params.id;
        setProfileId(id)
        console.log("FOCUS:" + id)
        return fetch(API_HOST + '/clinic/profiles/' + id, { credentials: 'include' })
      })().then((res) => res.json()).then((json) => {
        if (json['status'] == 'success') {
          const data = json['data'][0]

          setName(data.name)
          setCountry(data.country)
          setRegion(data.region)
          setCity(data.city)
          setAddress(data.address)
          setBio(data.bio)
          setDisplayPictureUri(data.display_picture_url)

          setDisplayPicture(new Blob())
          setPageHeading(data.name)
          setIsLoading(false);
        } else {
          alert(json['message']);
        }
      })

      return () => {
        console.log("CLEANUP!")
        // Do something when the screen is unfocused
        setIsLoading(true);
      }
    }, [props.route.params.id])
  )

  const submitForm = () => {
    const xsrfPreflight = () => {
      return fetch(API_HOST + '/auth/csrf-cookie', {
        method: 'GET',
        credentials: 'include',
      })
    }

    const readXsrfCookie = () => {
      const cookieName = 'XSRF-TOKEN'
      const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
      if (!match || !match[2]) {
        console.log('Error fetching XSRF cookie.');

        return '';
      }

      // Cookie is url encoded - if we don't decode it, the server will throw an error when trying to read it.
      const xsrfCookieValue = decodeURIComponent(match[2]);
      return xsrfCookieValue;
    }

    const updateClinic = () => {
      setIsSaving(true);
      const xsrfCookie = readXsrfCookie()

      const data: RequestInit = {
        method: 'PATCH',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          name: name,
          country: country,
          region: region,
          city: city,
          address: address,
          bio: bio,
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': xsrfCookie
        }
      }

      return fetch(API_HOST + '/clinic/profiles/' + profileId, data)
        .then(response => response.json())
        .then(json => {
          if (json['status'] === 'fail') {
            alert('Failed to save data!')
            setIsSaving(false)
          } else if (json['status'] === 'success') {

            // If a new image was chosen, then the displayPicture var will not be an empty Blob
            // In that case we want to upload the new photo
            if (displayPicture.size != 0) {
              const formData = new FormData()
              formData.append('displayPicture', displayPicture)

              const form: RequestInit = {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                body: formData,
                headers: {
                  'Accept': 'application/json',
                  'X-XSRF-TOKEN': xsrfCookie
                }
              }

              // Upload Clinic Profile Image
              fetch(API_HOST + '/clinic/profiles/' + profileId + '/display-pictures', form)
                .then(response => response.json())
                .then(json => {

                  if (json['status'] === 'fail') {
                    alert('Failed to save image!')
                    setIsSaving(false)
                  } else if (json['status'] === 'success') {

                    toast.show({
                      title: 'Clinic Updated!',
                      placement: 'bottom-right',
                      // variant: 'solid',
                      // status: 'success',
                      // isClosable: false,
                      // color: 'blue.400',
                      backgroundColor: 'green.600',
                      marginRight: 20,
                      paddingX: 8,
                      paddingY: 5
                    })

                    props.navigation.navigate("ClinicProfiles")
                    setIsSaving(false)
                  }
                });
            } else {
              // No photo update needed; the update is done!
              toast.show({
                title: 'Clinic Updated!',
                placement: 'bottom-right',
                // variant: 'solid',
                // status: 'success',
                // isClosable: false,
                // color: 'blue.400',
                backgroundColor: 'green.600',
                marginRight: 20,
                paddingX: 8,
                paddingY: 5
              })

              props.navigation.navigate("ClinicProfiles")
              setIsSaving(false)
            }
          } else {
            alert('clinic update response error')
          }
        })
    }

    if (readXsrfCookie()) {
      return updateClinic();
    } else {
      return xsrfPreflight().then(() => updateClinic())
    }
  }

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    const imageData = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!imageData.cancelled) {
      const imageStream = await fetch(imageData.uri)
      const imageFile = await imageStream.blob()
      const imageUrl = URL.createObjectURL(imageFile)
      setDisplayPicture(imageFile)
      setDisplayPictureUri(imageUrl)
    }
  };

  const floatingInputProps = {
    borderRadius: "4",
    labelColor: "#9ca3af",
    labelBGColor: useColorModeValue("#fff", "#1f2937"),
    _text: {
      fontSize: "sm",
      fontWeight: "medium",
    },
    _dark: {
      borderColor: "coolGray.700",
    },
    _light: {
      borderColor: "coolGray.300",
    },
  }

  return (
    <DashboardLayout title="Clinic Profile" navigation={props.navigation}>
      <HStack py={3} alignItems="center" justifyContent="space-between">
        <Hidden till="md">
          <Text
            fontSize="2xl"
            fontWeight="bold"
            _light={{ color: 'coolGray.800' }}
          >
            Edit {pageHeading}
          </Text>
        </Hidden>
      </HStack>
      {isLoading
        ?
        <HStack w="100%" h="200" space={10} justifyContent="center" alignItems="center">
          <Spinner size="lg" color='primary.900' />
        </HStack>
        :
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 16, md: 8 }}
          borderRadius={{ md: '8' }}
          _light={{
            borderColor: 'coolGray.200',
            bg: { base: 'white' },
          }}
          _dark={{
            borderColor: 'coolGray.800',
            bg: { md: 'coolGray.900', base: 'coolGray.800' },
          }}
          borderWidth={{ md: '1' }}
          borderBottomWidth="1"
          space="4"
        >
          <FloatingLabelInput
            {...floatingInputProps}
            isRequired
            label="Clinic Name"
            defaultValue={name}
            onChangeText={(txt: string) => setName(txt)}
          />
          <FloatingLabelInput
            {...floatingInputProps}
            isRequired
            label="Country"
            defaultValue={country}
            isDisabled={true}
          // onChangeText={(txt: string) => setCountry(txt)}
          />
          <FloatingLabelInput
            {...floatingInputProps}
            isRequired
            label="Region"
            defaultValue={region}
            isDisabled={true}
          // onChangeText={(txt: string) => setRegion(txt)}
          />
          <FloatingLabelInput
            {...floatingInputProps}
            isRequired
            label="City"
            defaultValue={city}
            isDisabled={true}
          // onChangeText={(txt: string) => setCity(txt)}
          />
          <FloatingLabelInput
            {...floatingInputProps}
            isRequired
            label="Address"
            defaultValue={address}
            onChangeText={(txt: string) => setAddress(txt)}
          />
          <Text color={"coolGray.400"}>Add Profile Picture</Text>
          <HStack>
            {!displayPictureUri &&
              <IconButton
                variant="subtle"
                h={200}
                w={200}
                m={2}
                borderRadius={'full'}
                onPress={pickImage}
                icon={
                  <Icon
                    size="6"
                    name="ios-add-outline"
                    as={Ionicons}
                    _light={{ color: 'coolGray.800' }}
                    _dark={{ color: 'coolGray.50' }}
                  />
                }
              />
            }
            {displayPictureUri &&
              <Pressable
                onPress={pickImage}
                p={2}
                borderRadius={'full'}
                _hover={{
                  backgroundColor: 'primary.200'
                }}
              >
                <Image
                  w={200}
                  h={200}
                  alt="Clinic Profile Display Picture"
                  borderRadius={'full'}
                  source={{ uri: displayPictureUri }}
                />
              </Pressable>
            }
          </HStack>
          <TextArea
            autoCompleteType={false}
            h={40}
            placeholder={'Add a description'}
            maxLength={500}
            defaultValue={bio}
            onChangeText={(txt: string) => setBio(txt)}
          />
          <Button
            mt={10}
            onPress={submitForm}
            isLoading={isSaving}
            size="md"
            borderRadius="4"
            _text={{
              fontSize: 'sm',
              fontWeight: 'medium',
            }}
            _light={{
              bg: 'primary.900',
            }}
            _dark={{
              bg: 'primary.700',
              _pressed: { bg: 'primary.500' },
            }}
          >
            Update Profile
          </Button>
        </VStack>
      }
    </DashboardLayout>
  );
}
